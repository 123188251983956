.App {
  font-family: Arial, sans-serif;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}


.App-header {
  background-color: #282c34;
  color: white;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.full-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start; /* align text to the left */
  padding-left: 50px; /* add buffer from the left edge */
}


main {
  flex: 1;
  margin-top: 60px; /* Adjusts for the fixed header */
}


.cv-link, .name-link {
  color: white;
  text-decoration: none;
  transition: opacity 0.3s ease;
  padding-right: 2%; /* space from the right edge */
}

footer {
  background-color: #282c34;
  color: white;
  padding: 10px 0;
  text-align: center;
  width: 100%;
}


h2 {
  margin-top: -50px; /* adjust this value for desired effect */
  padding-top: 50px;
  border-bottom: 2px solid #282c34;
}

.intro-section {
  align-items: center;
}
